import {
    Exercise,
    GameplayMode,
    PartialExerciseResult,
} from "@evidenceb/gameplay-interfaces";
import parse from "html-react-parser";
import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";
import ChatBubble from "./ChatBubble/ChatBubble";

type ExerciseProps = {
    currentExercise: Exercise<any, any>;
    onExerciseResult: (exerciseResult: PartialExerciseResult<any>) => void;
};

const CurrentExercise: React.FC<ExerciseProps> = ({
    currentExercise,
    onExerciseResult,
}): JSX.Element => {
    const {
        config: { i18n },
    } = useContext(configStore);

    return (
        <div className="current-exercise">
            <div className="current-exercise-chat-bubble">
                <ChatBubble
                    direction="left"
                    fullWidth={true}
                    color="var(--chatbot-color-bot-bubble)"
                    botAvatar="visible"
                >
                    {currentExercise.instruction && (
                        <p className="current-instruction">
                            {parse(currentExercise.instruction?.$html)}
                        </p>
                    )}

                    <ChatBubble
                        direction="right"
                        fullWidth={true}
                        marginBottom={false}
                        color=""
                    >
                        {currentExercise.messages ? (
                            <div className="additional-instructions">
                                {currentExercise.messages?.map((message) => (
                                    <p
                                        className="additional-instruction"
                                        key={message.$html}
                                    >
                                        {parse(message.$html)}
                                    </p>
                                ))}
                            </div>
                        ) : null}
                        <currentExercise.Gameplay
                            mode={GameplayMode.Interactive}
                            data={currentExercise.data}
                            onExerciseResult={(exerciseResult) =>
                                onExerciseResult(exerciseResult)
                            }
                            // TODO: figure out
                            onTrackingEvent={() => null}
                            // TODO:
                            // - make optional
                            // - don't use
                            showCorrectAnswer={false}
                            // TODO: use another way
                            i18n={{
                                ...i18n.gameplays.generic,
                                ...i18n.gameplays[currentExercise.type],
                            }}
                        />
                    </ChatBubble>
                </ChatBubble>
            </div>
        </div>
    );
};

export { CurrentExercise };

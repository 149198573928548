import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";

import "./ExerciseIdentifier.scss";

const ExerciseIdentifier: React.FC<{
    moduleId: string;
    objectiveId: string;
    activityId: string;
    exerciseId: string;
}> = ({ moduleId, objectiveId, activityId, exerciseId }) => {
    const {
        config: { client_name, i18n, logos },
    } = useContext(configStore);

    return (
        <div className="exercise-identifier">
            <div className="container">
                <div className="logo">
                    <img src={logos.header_client} alt={client_name} />
                </div>
                <div className="identifier">
                    <span>{i18n.hierarchy.module.short}</span>.{moduleId}
                    <>
                        {" - "}
                        <span>{i18n.hierarchy.objective.short}</span>.
                        {objectiveId}
                    </>
                    <>
                        {" - "}
                        <span>{i18n.hierarchy.activity.short}</span>.
                        {activityId}
                    </>
                    <>
                        {" - "}
                        <span>{i18n.hierarchy.exercise.short}</span>.
                        {exerciseId}
                    </>
                </div>
            </div>
        </div>
    );
};

export default ExerciseIdentifier;

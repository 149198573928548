import React from "react";
import "./Dashboard.scss";

import { Helmet } from "react-helmet";
import { Title } from "../../interfaces/Config";

function Dasboard({ title }: Title) {
    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div className="dashboard">
                <h1>Dashboard (Work in progress)</h1>
            </div>
        </>
    );
}

export default Dasboard;

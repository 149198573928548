import React from "react";

import "./Loader.scss";

const Loader: React.FC = () => {
    return (
        <div className="evb-spinner__container evb-spinner--animate">
            <div className="evb-spinner__circle evb-spinner--outer"></div>
            <div className="evb-spinner__dot evb-spinner--outer"></div>
            <div className="evb-spinner__circle evb-spinner--middle"></div>
            <div className="evb-spinner__dot evb-spinner--middle"></div>
            <div className="evb-spinner__circle evb-spinner--inner"></div>
            <div className="evb-spinner__dot evb-spinner--inner"></div>
            <div className="evb-spinner__dot evb-spinner--outer evb-spinner--bottom"></div>
        </div>
    );
};

export default Loader;

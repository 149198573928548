import { HierarchyIds } from "@evidenceb/gameplay-interfaces";

/**
 * Interfaces for the Bandit Manchot
 * 
 * The bandit manchot is composed of several instances, one for each module.
 */

export interface BanditManchot {
    [moduleId: string]: {
        historyLoaded: boolean;
        instance: BanditManchotInstance;
    };
}

export interface BanditManchotInstance {
    sample_from_history: (history: HistoryItem[]) => HierarchyIds;
    get_student_diagnostic: () => Diagnostic
};

export interface HistoryItem {
    exerciseId: string;
    activityId: string;
    objectiveId: string;
    /** Number between 0 and 1 */
    score: number;
}

/**
 * The diagnostic describes a student's progress within a module.
 * The opening state corresponds to the state of the objective if the main key
 * is the module id, or the state of the activities if the main key is an
 * objective's id.
 */
export interface Diagnostic {
    [moduleOrObjectiveId: string]: {
        param_state: [OpeningState[]]; // Don't ask why it's in an array. I'm not the AI specialist behind that API.
        prog_speeds: any; // TODO: Figure this out.
    };
}

/**
 * This enum describes the state of the progress of a student regarding an
 * activity or objective. Unopened means the activity/objective is inaccessible,
 * opened that it is accessible, and closed that it is finished.
 */
export enum OpeningState {
    Unopened = 0,
    Opened = 1,
    Closed = 2,
}
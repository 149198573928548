import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

let matomoInstance;

if (process.env.NODE_ENV === "production") {
    matomoInstance = createInstance({
        urlBase: "https://charlemagne.evidenceb-services.com",
        siteId: 1,
        linkTracking: false,
    });

    Sentry.init({
        dsn:
            "https://7467730f826844f68ecd3b39ec8b0ca8@o340691.ingest.sentry.io/5601505",
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV,
    });
}

ReactDOM.render(
    <React.StrictMode>
        {typeof matomoInstance === "undefined" ? (
            <App />
        ) : (
            <MatomoProvider value={matomoInstance}>
                <App />
            </MatomoProvider>
        )}
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

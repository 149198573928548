import { useEffect, useState } from "react";
import chatbotTheme from "./chatbotTheme";

function injectCss(css: string) {
    const style = document.createElement("style");
    // The following line is required for this to work on WebKit
    style.appendChild(document.createTextNode(""));
    document.head.appendChild(style);
    style.sheet!.insertRule(css, 0);
}

function addContentPageCss(customCss: string) {
    const style = document.createElement("style");
    style.appendChild(document.createTextNode(customCss));
    document.head.appendChild(style);
}

export interface Theme {
    baseFontSize: number;
    colorPrimary: string;
    colorPrimaryLight: string;
    colorSecondary: string;
    colorCorrect: string;
    colorError: string;
    contentPagesStyle: string;
}

async function fetchTheme(): Promise<Theme> {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    /* TEMP */
    let client: string | null = window.location.search.split("?token=")[1];

    if (client) {
        localStorage.setItem("client", client);
    } else {
        let hostname = window.location.hostname.split(".")[1];
        console.log("hostname", hostname);
        if (hostname === "localhost") {
            client = localStorage.getItem("client");
        } else {
            client = hostname;
            localStorage.setItem("client", client);
        }
    }
    /* --- */

    const response = await fetch(
        `https://cellar-c2.services.clever-cloud.com/${client}-bucket/versions/default/main/config/theme.json`,
        { cache: "no-cache" }
    );
    return response.json();
}

export function applyTheme(theme: Theme) {
    injectCss(`
        :root {
            --base-font-size:${theme.baseFontSize}px;
            --color-primary: ${theme.colorPrimary};
            --color-primary-light: ${theme.colorPrimaryLight};
            --color-secondary: ${theme.colorSecondary};
            --color-correct: ${theme.colorCorrect};
            --color-incorrect: ${theme.colorError};
        }
    `);
    addContentPageCss(theme.contentPagesStyle);
}

type ThemeState =
    | { status: "loading" }
    | { status: "loaded" }
    | { status: "error" };

export default function useTheme() {
    const [themeState, setThemeState] = useState<ThemeState>({
        status: "loading",
    });
    useEffect(() => {
        fetchTheme()
            .then((theme) => {
                console.log("fetchTheme DATA |", theme);
                applyTheme(theme);
                setThemeState({ status: "loaded" });
            })
            .catch((err) => {
                console.log("fetchTheme ERROR |", err);
                setThemeState({ status: "error" });
            });
    }, []);
    return themeState;
}

import React, { useEffect } from "react";
import { ConfigProvider } from "./contexts/ConfigContext";
import { HomeProvider } from "./contexts/HomeContext";
import { ContentPagesProvider } from "./contexts/ContentPagesContext";
import { DataProvider } from "./contexts/DataContext";
import Root from "./pages/Root/Root";
import "./scss/App.scss";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { BanditManchotProvider } from "./contexts/BanditManchotContext";

const App = () => {
    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();
    useEffect(() => {
        trackPageView({});
    }, [trackPageView]);

    return (
        <DataProvider>
            <ConfigProvider>
                <HomeProvider>
                    <ContentPagesProvider>
                        <BanditManchotProvider>
                            <Root />
                        </BanditManchotProvider>
                    </ContentPagesProvider>
                </HomeProvider>
            </ConfigProvider>
        </DataProvider>
    );
};

export default App;

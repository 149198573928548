import { HierarchyIds } from "@evidenceb/gameplay-interfaces";
import { BanditManchotInstanceNotFoundError } from "../errors";
import {
    BanditManchot,
    BanditManchotInstance,
    Diagnostic,
    HistoryItem,
    OpeningState,
} from "../interfaces/BanditManchot";
import { getModuleHistory } from "./localStorage";

/**
 * Loads the module history for all modules for which the history was not loaded yet.
 * Returns the instances with the historyLoaded parameter set to true.
 */
export const loadAllModulesHistory = (
    instances: BanditManchot,
    moduleIds: string[]
): BanditManchot => {
    const bmSetAsLoaded: BanditManchot = {};
    moduleIds.forEach((moduleId) => {
        const history = getModuleHistory(moduleId);
        if (!instances[moduleId]) throw new BanditManchotInstanceNotFoundError();
        if (instances[moduleId].historyLoaded) return;
        loadHistoryAndGetNextHierarchyIds(
            instances[moduleId].instance,
            history
        );
        bmSetAsLoaded[moduleId] = {
            ...instances[moduleId],
            historyLoaded: true,
        };
    });
    return bmSetAsLoaded;
};

export const loadHistoryAndGetNextHierarchyIds = (
    instance: BanditManchotInstance,
    history: HistoryItem[]
): HierarchyIds => {
    return instance.sample_from_history(history);
};

export const updateHistoryAndGetNextHierarchyIds = (
    instance: BanditManchotInstance,
    historyItem: HistoryItem
): HierarchyIds => {
    return instance.sample_from_history([historyItem]);
};

export const getNextHierarchyId = (
    instance: BanditManchotInstance
): HierarchyIds => {
    return instance.sample_from_history([]);
};

/**
 * Returns a student's progression, which corresponds to the percentage of open
 * activities in the module
 */
export const getStudentModuleProgression = (
    instance: BanditManchotInstance,
    moduleId: string
): number => {
    const diagnostic: Diagnostic = instance.get_student_diagnostic();
    let totalActivities = 0;
    let openActivities = 0;
    Object.keys(diagnostic).forEach((key) => {
        // Ignore the data filed under the module id, as they correspond to the
        // state of objectives, not activities
        if (key === moduleId) return;

        diagnostic[key].param_state[0].forEach((state) => {
            totalActivities += 1;
            if (state === OpeningState.Opened || state === OpeningState.Closed)
                openActivities += 1;
        });
    });
    return Math.floor((100 * openActivities) / totalActivities);
};

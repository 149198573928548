import { ActivityShell } from "@evidenceb/gameplay-interfaces";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ChatbotShell from "../../components/ChatbotShell/ChatbotShell";
import ExerciseIdentifier from "../../components/ExerciseIdentifier/ExerciseIdentifier";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import WizardShell from "../../components/WizardShell/WizardShell";
import { banditManchotStore } from "../../contexts/BanditManchotContext";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { BanditManchotInstanceNotFoundError } from "../../errors";
import useBanditManchotPlaylistManager from "../../hooks/useBanditManchotPlaylistManager";
import {
    getStudentModuleProgression, loadHistoryAndGetNextHierarchyIds,
} from "../../utils/banditManchot";
import { getModuleById } from "../../utils/dataRetrieval";
import { getModuleHistory } from "../../utils/localStorage";
import parse from "html-react-parser";

import "./PlaylistPlayer.scss";

const BanditManchotPlaylistPlayer: React.FC = () => {
    const { moduleId } = useParams<{ moduleId: string }>();
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);

    const {
        playlist,
        goToNextExercise,
        recordCurrentExerciseResult,
    } = useBanditManchotPlaylistManager(moduleId);

    // Init bandit manchot
    const { banditManchot, setBanditManchot } = useContext(banditManchotStore);
    if (!banditManchot[moduleId]) throw new BanditManchotInstanceNotFoundError();
    if (!banditManchot[moduleId].historyLoaded) {
        const history = getModuleHistory(moduleId);
        loadHistoryAndGetNextHierarchyIds(banditManchot[moduleId].instance, history);
        setBanditManchot({
            ...banditManchot,
            [moduleId]: {
                ...banditManchot[moduleId],
                historyLoaded: true
            }
        })
    }

    const [progression, setProgression] = useState<number>(
        getStudentModuleProgression(banditManchot[moduleId].instance, moduleId)
    );

    // TODO: What happens if the shell type changes mid-playlist ?
    const Shell =
        playlist.activity.shell === ActivityShell.Chatbot
            ? ChatbotShell
            : WizardShell;

    return (
        <div className="playlist-player-container player-container player-container--bandit-manchot">
            <PlayerHeader>
                <Link to="/modules" className="player-header__exit">
                    <div className="material-icons exit__icon">arrow_back</div>
                    <div>{config.i18n.exerciseShell.close}</div>
                </Link>
                <div className="player-header__progression">
                    <h2>{parse(getModuleById(moduleId, data).description.$html)}</h2>
                    <ProgressBar completion={progression}/>
                </div>
                <div></div>
            </PlayerHeader>

            {playlist.currentExercise && (
                <div className="main__wrapper">
                    <main>
                        <Shell
                            currentExercise={playlist.currentExercise!}
                            currentTry={playlist.currentTry!}
                            comingNext={playlist.comingNext}
                            currentExerciseResult={
                                playlist.currentExerciseResult
                            }
                            onGoToNextExercise={goToNextExercise}
                            onExerciseResult={(result) => {
                                recordCurrentExerciseResult(result);
                                setProgression(
                                    getStudentModuleProgression(
                                        banditManchot[moduleId].instance,
                                        moduleId
                                    )
                                );
                            }}
                            playlist={playlist}
                        />
                    </main>

                    <ExerciseIdentifier
                        moduleId={playlist.module.id}
                        objectiveId={playlist.objective.id}
                        activityId={playlist.activity.id}
                        exerciseId={playlist.currentExercise!.id}
                    />
                </div>
            )}
        </div>
    );
};

export default BanditManchotPlaylistPlayer;

import React, { useContext } from "react";
import { configStore } from "../../contexts/ConfigContext";
import { contentPagesStore } from "../../contexts/ContentPagesContext";
import { dataStore } from "../../contexts/DataContext";
import { homeStore } from "../../contexts/HomeContext";
import { applyTheme, Theme } from "../../hooks/usetheme";
import { Config } from "../../interfaces/Config";
import { ContentPage } from "../../interfaces/ContentPage";
import { RawData } from "../../interfaces/Data";
import { Home } from "../../interfaces/Home";
import { getExercisesWithAvailableGameplays } from "../../utils/fetch-gameplays";
import windowLogger from "../../utils/window-logger";

/**
 * This components adds function to the window object to be used to import
 * questions from a file or an object on the fly, for development purposes.
 */
const WindowQuestionLoader: React.FC = () => {
    const { setData } = useContext(dataStore);
    const { setConfig } = useContext(configStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);

    /**
     * Takes a RawData object, and adds it to the app's context after fetching
     * the associated gameplay
     * @param incomingData
     */
    const loadRawBundle = async (incomingData: RawData): Promise<void> => {
        const questions = await getExercisesWithAvailableGameplays(
            incomingData.exercises,
            (exercise, reason) => {
                windowLogger.error(
                    `Question could not be imported: ${reason}`,
                    exercise.id
                );
            }
        );
        setData({
            modules: incomingData.modules,
            objectives: incomingData.objectives,
            activities: incomingData.activities,
            exercises: questions,
        });
    };

    /**
     * Downloads a json file and adds the data to the app's context after
     * fetching the associated gameplay.
     * @param url
     */
    const loadBundle = async (url: string): Promise<void> => {
        try {
            const response = await fetch(url);
            const incomingData = (await response.json()) as RawData;
            await loadRawBundle({
                modules: incomingData.modules,
                objectives: incomingData.objectives,
                activities: incomingData.activities,
                exercises: incomingData.exercises,
            });
        } catch (err) {
            windowLogger.error(`Cannot load file ${url}`);
        }
    };

    /**
     * Takes a home object, and adds it to the app's context
     * @param incomingData
     */
    const loadRawHome = (incomingHome: Home) => {
        setHome(incomingHome);
    };

    /**
     * Downloads a json file and adds the home to the app's context
     * @param url
     */
    const loadHome = async (url: string): Promise<void> => {
        try {
            const response = await fetch(url);
            const home = (await response.json()) as Home;
            loadRawHome(home);
        } catch (err) {
            windowLogger.error(`Cannot load file ${url}`);
        }
    };

    /**
     * Takes a content pages object, and adds it to the app's context
     * @param incomingData
     */
    const loadRawContentPages = async (incomingContentPages: ContentPage[]) => {
        setContentPages(incomingContentPages);
    };

    /**
     * Downloads a json file and adds the config to the app's context
     * @param url
     */
    const loadContentPages = async (url: string): Promise<void> => {
        try {
            const response = await fetch(url);
            const contentPages = (await response.json()) as ContentPage[];
            loadRawContentPages(contentPages);
        } catch (err) {
            windowLogger.error(`Cannot load file ${url}`);
        }
    };

    /**
     * Takes a config object, and adds it to the app's context
     * @param incomingData
     */
    const loadRawConfig = (incomingConfig: Config) => {
        setConfig(incomingConfig);
    };

    /**
     * Downloads a json file and adds the config to the app's context
     * @param url
     */
    const loadConfig = async (url: string): Promise<void> => {
        try {
            const response = await fetch(url);
            const config = (await response.json()) as Config;
            loadRawConfig(config);
        } catch (err) {
            windowLogger.error(`Cannot load file ${url}`);
        }
    };

    /**
     * Downloads a json file and adds the theme to the app's context
     * @param url
     */
    const loadTheme = async (url: string): Promise<void> => {
        try {
            const response = await fetch(url);
            const theme = (await response.json()) as Theme;
            applyTheme(theme);
        } catch (err) {
            windowLogger.error(`Cannot load file ${url}`);
        }
    };

    (window as any).loadBundle = loadBundle;
    (window as any).loadRawBundle = loadRawBundle;
    (window as any).loadConfig = loadConfig;
    (window as any).loadRawConfig = loadRawConfig;
    (window as any).loadHome = loadHome;
    (window as any).loadRawHome = loadRawHome;
    (window as any).loadContentPages = loadContentPages;
    (window as any).loadRawContentPages = loadRawContentPages;
    (window as any).loadTheme = loadTheme;
    (window as any).loadRawTheme = applyTheme;

    return <></>;
};

export default WindowQuestionLoader;

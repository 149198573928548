import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import { useContext, useEffect, useState } from "react";
import { configStore } from "../contexts/ConfigContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { dataStore } from "../contexts/DataContext";
import { homeStore } from "../contexts/HomeContext";
import { Config } from "../interfaces/Config";
import { ContentPage } from "../interfaces/ContentPage";
import { RawData } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ErrorLog, Status } from "../interfaces/Status";
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";
import { applyTheme, Theme } from "../utils/theme-handler";
import windowLogger from "../utils/window-logger";
import chatbotTheme from "./chatbotTheme";
import config from "../config";
import aiConfig from "./aiConfig";
import { getActivitiesInModule } from "../utils/dataRetrieval";
import { banditManchotStore } from "../contexts/BanditManchotContext";
import { BanditManchot } from "../interfaces/BanditManchot";

export interface GlobalConfig {
    config: Config;
    home: Home;
    contentPages: ContentPage[];
    theme: Theme;
}

export default function useSetContexts() {
    const { setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { setConfig } = useContext(configStore);
    const { setBanditManchot } = useContext(banditManchotStore);

    const [status, setStatus] = useState<Status>({
        type: "pending",
        errors: [],
    });

    useEffect(() => {
        const errorLog: ErrorLog[] = [];
        function getJson<T>(url: string): Promise<T> {
            return fetch(url, { cache: "no-cache" })
                .then((response) => {
                    return response.json() as Promise<T>;
                })
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    errorLog.push({ info: err, type: "JSON" });
                    return err;
                });
        }

        async function getAllJson() {
            /* TEMP */
            let client;
            let hostname = window.location.hostname.split(".")[1]; // TEMP workaround
            hostname = hostname === "adaptivlangue" ? "adlang" : hostname;
            if (hostname === "localhost") {
                client = localStorage.getItem("client");
            } else {
                client = hostname;
                localStorage.setItem("client", client);
            }
            /* --- */

            //Sequence of async fetch
             /* --- TEMP --- */
             // TO DO: call Ressources MS to get config.json
             const global = await getJson<GlobalConfig>(
                 config.configJsonUrl ??
                     `https://cellar-c2.services.clever-cloud.com/adlang-bucket/versions/default/idfspecimenhtml/config/config.json`
             );
            // Uncomment (and comment the statement above) to load the local config
            /* const global = (await import("./globalConfig")).default; */


            // TEMP: remove non-visible objects until they're handled properly
            // by the app
            // TO DO: call Ressources MS to get data.json
            const data = removeNonVisible(
                await getJson<RawData>(
                    config.dataJsonUrl ??
                        `https://cellar-c2.services.clever-cloud.com/${client}-bucket/versions/default/idfspecimenhtml/questions/data.json`
                )
            );
            // Uncomment (and comment the statement above) to load the local data
            // const data = (await import("./data")).default;
            
            registerDebugUtils(data);

            const exercises = await getExercisesWithAvailableGameplays(
                data.exercises,
                (exercise, reason) => {
                    windowLogger.error(
                        `Exercise could not be imported: ${reason}`,
                        exercise.id
                    );
                }
            );

            // TODO: Use actual config global.config.ai.config instead of aiConfig
            // Retrieve and instanciate AI
            const AI = (
                await import("@evidenceb/bandit-manchot/build/" + global.config.ai?.id)
            ).default;
            const banditManchot: BanditManchot = {};
            data.modules.forEach((module) => {
                banditManchot[module.id] = {
                    historyLoaded: false,
                    instance: new AI({
                        ...aiConfig.baseConfig,
                        graph: {
                            ...aiConfig.baseConfig.graph,
                            main_act: module.id,
                            graph: aiConfig.moduleConfig[module.id],
                            exercises_ontology: getActivitiesInModule(module, {
                                ...data,
                                exercises,
                            }),
                        },
                    }),
                };
            });

            // if no error is catch, set content and send fetched status, if error(s) send error status and log to display appropriate error page
            if (errorLog.length === 0) {
                applyTheme({ ...global.theme, chatbot: chatbotTheme }); //TODO: fallback theme, theme errors handler
                setConfig(global.config);
                setHome(global.home);
                setContentPages(global.contentPages);
                setData({
                    modules: data.modules,
                    objectives: data.objectives,
                    activities: data.activities,
                    exercises,
                });
                setBanditManchot(banditManchot);
                setStatus({ type: "fetched", errors: [] });
            } else {
                setStatus({ type: "error", errors: errorLog });
            }
        }

        getAllJson();
    }, [setConfig, setContentPages, setData, setHome]);

    return { status };
}

function removeNonVisible(data: RawData): RawData {
    const exercises = data.exercises.filter(isVisible);
    const existsInExercises = existsIn(exercises);
    const activities = data.activities.filter(isVisible).map((activity) => ({
        ...activity,
        exerciseIds: activity.exerciseIds.filter(existsInExercises),
    }));
    const existsInActivities = existsIn(activities);
    const objectives = data.objectives.filter(isVisible).map((objective) => ({
        ...objective,
        activityIds: objective.activityIds.filter(existsInActivities),
    }));
    const existsInObjectives = existsIn(objectives);
    const modules = data.modules.filter(isVisible).map((module) => ({
        ...module,
        objectiveIds: module.objectiveIds.filter(existsInObjectives),
    }));
    return { modules, objectives, activities, exercises };
}

function isVisible(object: { visibilityStatus: VisibilityStatus }): boolean {
    return object.visibilityStatus === VisibilityStatus.Visible;
}

function existsIn(elements: { id: string }[]) {
    const ids = new Set(elements.map((element) => element.id));
    return (id: string): boolean => ids.has(id);
}

function registerDebugUtils(data: RawData) {
    (window as any).DEBUG_UTILS = {
        getModuleById: (id: string) =>
            data.modules.find((module) => module.id === id),
        getObjectiveById: (id: string) =>
            data.objectives.find((objective) => objective.id === id),
        getActivityById: (id: string) =>
            data.activities.find((activity) => activity.id === id),
        getExerciseById: (id: string) =>
            data.exercises.find((exercise) => exercise.id === id),
    };
}

import React, { useContext, useEffect, useState, useRef } from "react";
import parse from "html-react-parser";
import { ExerciseResult, Shell } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../contexts/ConfigContext";
import WizardFeedbackDisplay from "../WizardFeedbackDisplay/WizardFeedbackDisplay";
import Button from "../Button/Button";

import "./WizardShell.scss";
import { getFeedbackButtonText } from "../../utils/feedbackUtils";

/**
 * The WizardShell instanciates the Gameplay component from the library and
 * handles interactions with it.
 */
const WizardShell: React.FC<Shell<any, any>> = ({
    currentExercise,
    currentTry,
    comingNext,
    currentExerciseResult,
    onGoToNextExercise,
    onExerciseResult,
}) => {
    const { config } = useContext(configStore);
    const [animateFeedback, setAnimateFeedback] = useState<boolean>(false);

    const gpWrapper = useRef<HTMLDivElement>(null);
    const feedback = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isExerciseSolved(currentExerciseResult)) return;

        setTimeout(() => {
            setAnimateFeedback(true);
        });
    }, [currentExerciseResult]);

    if (!currentExercise) return <></>;

    return (
        <div className="wizard-shell-container">
            {currentExercise.instruction ? (
                <div className="instruction">
                    <div className="content">
                        <p>{parse(currentExercise!.instruction.$html)}</p>
                    </div>
                </div>
            ) : null}

            <div
                className="gp-container"
                style={{
                    paddingBottom: feedback.current
                        ? feedback.current.clientHeight + 24
                        : 0,
                }}
            >
                <div
                    className={`gp-wrapper ${
                        isExerciseSolved(currentExerciseResult) ? "solved" : ""
                    }`}
                    ref={gpWrapper}
                >
                    <currentExercise.Gameplay
                        key={currentTry}
                        data={currentExercise.data}
                        showCorrectAnswer={
                            !isExerciseSolved(currentExerciseResult) ||
                            comingNext === "retry"
                                ? false
                                : currentExercise.showCorrectAnswer!
                        }
                        i18n={{
                            ...config.i18n.gameplays.generic,
                            ...config.i18n.gameplays[currentExercise.type]
                        }}
                        onComplete={(isCorrect) => {
                            onExerciseResult({
                                correct: isCorrect,
                            });
                        }}
                        onExerciseResult={onExerciseResult}
                        onTrackingEvent={(statement) => {
                            // TODO: Handle tracking when AI should be implemented
                            console.log("Tracking:", statement);
                        }}
                    />
                </div>

                {isExerciseSolved(currentExerciseResult) ? (
                    <div
                        className={`wizard-feedback-container ${
                            animateFeedback ? "--animate-feedback" : ""
                        }`}
                        ref={feedback}
                    >
                        <WizardFeedbackDisplay
                            isCorrect={currentExerciseResult!.correct}
                            title={currentExerciseResult!.feedback?.title}
                            text={currentExerciseResult!.feedback?.text}
                            image={currentExerciseResult!.feedback?.image}
                            imageAlt={currentExerciseResult!.feedback?.imageAlt}
                        />

                        <div className="navigation-button">
                            <Button
                                style="primary"
                                label={getFeedbackButtonText(
                                    comingNext!,
                                    config
                                )}
                                icon={
                                    comingNext === "endOfPlaylist"
                                        ? ""
                                        : "arrow_forward"
                                }
                                centeredLabel={
                                    comingNext === "endOfPlaylist"
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    onGoToNextExercise();
                                }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const isExerciseSolved = (
    exerciseResult: ExerciseResult<any> | undefined
): boolean => typeof exerciseResult !== "undefined";

export default WizardShell;
